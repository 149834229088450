$(document).ready(function() {
    $('[data-toggle="datepicker"]').datepicker({
            format: 'd.m.yyyy',
            //language: 'cs'
    });

    $(".jsLink").click(function(){
         window.location=$(this).find("a").attr("href");
         return false;
    });

    $('.closeModal').click(function(){
        $('body').removeClass('locked');
        $('#modal').removeClass('active');
    });

    $('.jsModal').click(function(){
        $('body').addClass('locked');
        $('#modal').addClass('active');
    });
    $(".morePhoto").click(function(){
        $(this).hide();
        $('.photosHide').removeClass('hidden');
    });

    $(".newLineph").click(function(){
        str  = '<div class="col-md-12 d-flex align-items-center my-2">';
        str += '    <label>Poznámky</label>';
        str += '    <input type="text" name="poznamka_ph[]" value="" />';
        str += '</div>';


        $( ".lines-ph" ).append( str );

    });

    $(".newLine").click(function(){
        str  = '<div class="col-md-5 d-flex align-items-center my-2">';
        str += '    <label>Termín</label>';
        str += '    <input type="text" data-toggle="datepicker" name="senzorika_termin[]" value="" />';
        str += '</div>';
        str += '<div class="col-md-7 d-flex align-items-center my-2">';
        str += '    <label>Poznámky</label>';
        str += '    <input type="text" name="poznamka_termin[]" value="" />';
        str += '</div>';

        $( ".lines" ).append( str );

        $('[data-toggle="datepicker"]').datepicker({
                format: 'd.m.yyyy',
                //language: 'cs'
        });
    });
});
